import "./style.scss";

import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import { Header } from "common/components";
import { MAIN_PAGE_TITLE } from "config/constants";
import { isValidArticleMetadata } from "helpers/metadata-helper";
import UrlHelper from "helpers/url-helper";
import { IArticleMetadata, MetadataType } from "lib/interfaces/metadata";
import LoadingScreen from "modules/components/LoadingScreen";
import { useMetadataArticlesFilter } from "modules/hooks";
import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import ArticleItem from "../ArticlesByDate/ArticleItem";
import InternalServerErrorPage from "../InternalServerError";
import NotFoundPage from "../NotFound";

interface ArticlesByCategoryProps extends WithBundleProps {
  type: MetadataType;
}

// ArticlesByCategoryParams category is the params from the route: `/categories/:category`
type ArticlesByCategoryParams = {
  category: string;
};

const ArticlesByCategory = (props: ArticlesByCategoryProps) => {
  const { bundle } = props;
  const { category: categoryEncoded } = useParams<ArticlesByCategoryParams>();
  // Need to decode category since it gets encoded by default
  // e.g. "L&D Category" gets encoded to "L%26D%20Content" by default, so need to decode it back to original value
  // before use
  const category = decodeURIComponent(categoryEncoded);

  const { metadataByCategory, isError, isLoading } = useMetadataArticlesFilter({
    filter: props.type,
    filteringList: [category],
  });
  // Find response for the category
  // Category could have '-' character, so replace that with space
  // e.g. "Amazon-Account-Test" => "Amazon Account Test"
  const matchedCategory = Object.keys(metadataByCategory).find(
    (key) =>
      key.toLowerCase() === category.toLowerCase() || key.toLowerCase() === category.toLowerCase().replace(/-/g, " ")
  );
  const headerText = bundle.formatMessage("header", { matchedCategory });
  const articles = matchedCategory ? metadataByCategory[matchedCategory] : [];

  if (isLoading) {
    return <LoadingScreen show={true} />;
  }

  if (isError) {
    return <InternalServerErrorPage />;
  }

  if (!matchedCategory) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Helmet>
        <link rel="stylesheet" type="text/css" href={UrlHelper.getLegoStylesUrl()} />
        <title>{`${headerText} - ${MAIN_PAGE_TITLE}`}</title>
      </Helmet>

      <div className="article-list">
        <Header>{headerText}</Header>
        <br />
        {articles.map(
          (article: IArticleMetadata, index: number) =>
            isValidArticleMetadata(article) && <ArticleItem {...article} key={index} />
        )}
      </div>
    </>
  );
};

export default withBundle("com.a2z.it.kb.ArticlesByCategory")(ArticlesByCategory);
